import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import SingleExam from '../components/single-exam'

const Exam = ({ data, location, pageContext }) => {
  const exam = data.exams
  const wordpressData = data.wordpressWpGatsbyPage.acf
  const examCanonical = `${wordpressData.canonical + exam.slug}/`

  return (
    <Layout location={location} pageContext={pageContext} crumbLabel={exam.name}>
      <SEO
        title={exam.name}
        description={`${exam.name} Informações completas sobre o exame, orientações gerais, genes analisados e doenças relacionadas.`}
        canonical={examCanonical}
      />

      <SingleExam
        exam={exam}
        data={data}
      />
    </Layout>
  )
}

Exam.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default Exam

export const unitQuery = graphql`
  query($id: String!, $slug: String!) {
    exams(id: { eq: $id }) {
      id
      name
      specializationProduct {
        areaEspecializacao {
          nome
        }
      }
      category {
        id
        nome
      }
      diseases
      synonymies
      deadline
      ansCover
      genes
      informativeProduct {
        descricao
        metodo
        objetivo
      }
      preparation {
        descricao
      }
      material {
        nome
        id
      }
      materials {
        nome
        id
      }
      tussCode
      documents {
        name
        url
        id
      }
      showGeneCount
      slug
      vertical {
        id
        nome
        processos {
          descricao
          id
        }
      }
    }
    site {
      id
      siteMetadata {
        title
        subtitle
      }
    }
    wordpressWpGatsbyPage(slug: { eq: $slug }) {
      acf {
        canonical
        ans_label_exam_details
        others_names_exam_details
        title_diseases_section
        title_deadline_section
        description_deadline
        title_ans_coverage_section
        description_not_coverage_ans
        description_coverage_ans
        title_section_analyzed_genes
        description_section_analyzed_genes
        title_btn_copy_genes
        text_tooltip_copy_genes
        text_counter_genes
        title_btn_all_genes
        title_modal_genes
        title_btn_clone_modal_genes
        text_no_results
        title_appointment_flow
        description_appointment_flow
        schedule_exams_btn
        show_schedule_exams_btn
        btn_title_nav_appointment
        btn_link_nav_appointment
        btn_icon_nav_appointment
        btn_title_whatsapp_appointment
        btn_msg_whatsapp_appointment
        btn_link_whatsapp_appointment
        btn_icon_whatsapp_appointment
        show_collection_flow
        imagem_detalhamento_exame {
          alt_text
          localFile {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        texto_de_oque_e_exame
        texto_de_para_que_serve_exame
        texto_de_documentos_necessarios
        texto_de_preparo
        texto_de_tipo_de_amostra
        texto_de_metodologia
        texto_de_codigo_tuss
      }
    }
    pdfs: allFile(
    filter: {
      sourceInstanceName: { eq: "pdfs" },
      extension: { eq: "pdf" }
    } ) {
    edges {
      node {
        id
        name
        publicURL
        }
      } 
    }
  }
`
